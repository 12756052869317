<template>
  <div ref="map" class="Map" @touchstart.stop />
</template>

<script>
import { Loader } from 'google-maps';

const loader = new Loader('AIzaSyBaDxnKtLX7FXbZzMkdzFXNKvUDkH8wnnI');
const center = { lat: 47.3881019, lng: 8.4953504 };

export default {
  props: {
    payload: { type: Object, default: Object },
  },
  async mounted() {
    const google = await loader.load();
    this.map = new google.maps.Map(this.$refs.map, {
      disableDefaultUI: true,
      center,
      zoom: 18,
    });
    const marker = new google.maps.Marker({
      position: center,
      map: this.map,
    });
  },
  unmounted() {
    // this.map.destroy();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

.Map {
  width: 100%;
  height: 100%;
}
</style>
